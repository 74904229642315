@use '/styles/mixin/fonts.module.scss' as *;

.drive-404 {
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply max-w-full;
    @apply bg-no-repeat;
    @apply bg-center;
    @apply bg-cover;
    @apply overflow-hidden;
    @apply text-white;
    height: 80vh;

    @screen lg {
      @apply h-screen;
      @apply text-white;
    }
  }

  &__hero-text {
    font-size: 6.25rem;
    @apply tracking-tight;
    @screen lg {
      font-size: 8.75rem;
    }
    line-height: inherit;
  }

  &__sub-text {
    @apply -mt-8;
    @include font-heading('desktop', 'h2');
  }

  &__msg {
    @apply mb-5;
    @apply text-center;
    @include font-heading('desktop', 'h4');
    @screen lg {
      @apply mb-5;
      @apply text-center;
    }
  }

  &__go-back {
    @apply box-border;
    @apply rounded-lg;
    @apply py-2;
    @apply px-10;
    @apply bg-black;
    @apply border;
    @apply border-black;
    @apply border-solid;
    @apply text-white;
    @include font-button;
    @screen lg {
      @apply py-3;
      @apply px-10;
    }
  }
}
